
import { Vue, Component, Prop } from 'vue-property-decorator';
import { BillingInfo, Invoice } from '@/interface/interface';

@Component({})
export default class InvoiceConfirm extends Vue {
  @Prop() private invoiceList!: Invoice[];
  @Prop() private userGroup!: string;
  @Prop() private userGroupFullName!: string;
  @Prop() private billingAddress!: string;
  @Prop() private billingContactEmail!: string;
  @Prop() private terminateFee!: number;
  @Prop() private quotaFullFee!: number;
  @Prop() private digit!: string;
  @Prop() private currentDate!: Date;
  @Prop() private depositDeadLine!: Date;
  @Prop() private invoiceId!: string;
  private info: BillingInfo | null = null;
  private stamp: string | null = null;
  private rowspan = 4;
  async created(): Promise<void> {
    await this.getPMI();
  }
  async getPMI() {
    this.loading = true;
    const url = `/billing/pmi-info`;
    const { data, result } = await this.axios({
      url
    });
    if (result) {
      const { info, stamp } = data as { info: BillingInfo, stamp: string };
      this.info = info;
      this.stamp = stamp;
    }
    await new Promise((resolve) => setTimeout(resolve, 500));
    this.loading = false;
  }
  close() {
    this.$emit('close');
  }
  get grandTotal(): string {
    const sum = this.invoiceList.reduce((prev, cur) => prev + cur.cost.TOTAL, 0);
    return new Intl.NumberFormat().format(sum);
  }
  async save(): Promise<void> {
    this.proc(true);
    const url = `/billing/pdf-generate`;
    console.log({
      invoiceList: this.invoiceList,
    });
    const { data, result, message } = await this.axios({
      url,
      method: 'POST',
      data: {
        invoiceList: this.invoiceList,
        billingAddress: this.billingAddress,
        billingContactEmail: this.billingContactEmail,
      },
      responseType: 'blob'
    });
    if (result) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf');
      document.body.appendChild(link);
      this.close();
      link.click();
      document.body.removeChild(link);
      await this.$router.push({ path: `/project/billing/invoice`, query: { timeStamp: new Date().getTime().toString() } });
    }
    this.proc(false);
  }
}
